<template>
  <v-card elevation="0" class="mx-auto" color="transparent">
      <audio id="mySound" class="my_audio hidden" style="display: none" controls preload="none">
          <source src="../../assets/new_message.mp3" type="audio/mpeg">
      </audio>

      <v-card-title>
          Панель состояния

          <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                  <v-btn icon
                         class="ml-4">
                  <v-icon
                          v-on:click="accessAudio = !accessAudio"
                          v-on="on"
                          v-bind="attrs"
                  >{{accessAudio ? 'mdi-volume-high' : 'mdi-volume-off'}}
                  </v-icon>
                  </v-btn>
              </template>
              <span>Звуковые уведомления</span>
          </v-tooltip>

      </v-card-title>
      <v-row>
          <v-col cols="4">
              <StatisitcCard v-bind="{
                title: 'Заказов',
                icon: 'mdi-book-account-outline',
                amount: orders,
                action: 'Подробнее...',
                link: '/orders'
}"/>

          </v-col>
          <v-col cols="4">
              <StatisitcCard v-bind="{
                title: 'Оплачено',
                icon: 'mdi-credit-card-outline',
                amount: sells,
                action: 'Подробнее...',
                link: '/orders'
}"/>
          </v-col>
          <v-col cols="4">
              <StatisitcCard v-bind="{
                title: 'Уникальных клиентов',
                icon: 'mdi-account',
                amount: clients,
                action: 'Подробнее...',
                link: '/orders'
}"/>
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12">
              <OrdersHistory icon="mdi-book-account-outline" name="Последние заказы" is-orders="true" :headers="[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },
                  {
                      text: 'Дата',
                      value: 'Date',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  },
                  {
                      text: 'Загрузить',
                      value: 'AppendIcon',
                    sortable: false
                  }
              ]"
              :history="lastOrders"/>
          </v-col>
          <v-col cols="6">
              <OrdersHistory icon="mdi-cellphone" name="Ожидают звонка" is-orders="true" :headers="[
                  {
                      text: 'Номер заказа',
                      value: '@Order',
                      sortable: false
                  },
                  {
                      text: 'Дата',
                      value: 'Date',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  }
              ]"
                             :history="waitCalls"/>
          </v-col>
          <v-col cols="6">
              <OrdersHistory icon="mdi-calendar-account" name="Активность в админке" :headers="[
                  {
                      text: 'Дата',
                      sortable: false,
                      value: 'Datetime'
                  },
                  {
                      text: 'Действие',
                      value: 'Action',
                      sortable: false
                  },
                  {
                      text: 'Имя пользователя',
                      value: 'Name',
                      sortable: false
                  }
              ]" :history="journal"/>
          </v-col>
      </v-row>
  </v-card>
</template>

<script>

  import OrdersHistory from "../../components/OrdersHistory";
  import StatisitcCard from "../../components/StatisitcCard";

  import axios from 'axios';

  import {getURL, getWSSUrl} from "../../settings";
  export default {
    name: 'Home',
      data: () => {
        return {
            orders: 0,
            sells: 0,
            clients: 0,
            journal: [],
            lastOrders: [],
            waitCalls: [],
            audio: null,
            accessAudio: false,
        }
      },
    mounted() {
        this.audio = new Audio('../../assets/new_message.mp3');
        this.websocket = new WebSocket(getWSSUrl('ws'));
        this.websocket.onopen = (e) => {
            console.log('socket opened ', e);
        };

        this.websocket.onclose  = (e) => {
            console.log('socket closed ', e);
        };

        this.websocket.onerror = (e) => {
            console.log('socket on error', e)
        }

        this.websocket.onmessage = (e) => {
            const ws_message = e.data;
            let msg_tokens = ws_message.split(':');
            if(msg_tokens
                && msg_tokens.length === 2
                && msg_tokens[0] === 'order_created' ) {
                this.loadStat();
                if(this.accessAudio)
                    document.getElementById('mySound').play();
            }
            console.log('socket on message ', e.data)
        }
        this.loadStat();
    },
      methods: {
        loadStat() {
            axios.get(getURL('admin/stat')).then(resp => {
                let data = resp.data;
                this.orders = data['Orders'];
                this.sells = data['Sells'];
                this.clients = data['Clients'];
                this.journal = data['Journal'];
                this.lastOrders = data['LastOrders'];
                this.waitCalls = data['CallWaits'];

                console.log(this.journal)
            })
        }
      },
      components: {
        StatisitcCard,
        OrdersHistory
    },
  }
</script>
