<template>
    <div>
        <v-overlay
                style="position:fixed"
                :value="showHelp"
                :absolute="true">
            <v-card light class="main__stack-panel-right"
                    style="cursor: default; z-index: 5"
                    v-if="showHelp"
            >
                <v-card-actions>
                    <v-card-title>Справочник</v-card-title>
                    <v-spacer>

                    </v-spacer>
                    <v-btn class="transparent elevation-0" v-on:click="showHelp = false"><v-icon>mdi-close</v-icon>Закрыть</v-btn>
                </v-card-actions>
                <v-card-subtitle v-html="helpInfo">

                </v-card-subtitle>
            </v-card>
        </v-overlay>
        <v-card
                style="height: 94vh; overflow-y: auto; position: fixed; left: 0; width: 15vw"
                dark
        >
            <v-navigation-drawer
                    class="base__nav-drawer"
                    style="width: 100%"
                    permanent>
                <v-list v-for="module of modules" :key="module.name" class="py-0">
                    <template v-if="module.items && module.items.length > 1">
                        <v-list-item
                                v-on:click="onModuleClick(module.name)"

                        >
                            <v-list-item-icon>
                                <v-icon>{{modulesOpened.indexOf(module.name) !== -1 ? 'mdi-chevron-down' : 'mdi-chevron-right'}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content >
                                <v-list-item-title :class="'text-h8'"
                                                   style="cursor:pointer;"
                                >
                                    {{module.name}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider v-if="modulesOpened.indexOf(module.name) === -1"></v-divider>

                        <v-expand-transition>
                            <v-list
                                    dense
                                    nav
                                    v-if="modulesOpened.indexOf(module.name) !== -1"
                            >
                                <v-list-item
                                        v-for="item of module.items"
                                        :key="item.title"
                                        link
                                        v-on:click="onClickItem(item.link, item.onClick)"
                                >
                                    <v-list-item-icon>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 16px">{{ item.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </v-list>
                        </v-expand-transition>

                    </template>
                    <template v-else>
                        <v-list-item
                                :key="module.items[0].title"
                                link
                                v-on:click="onClickItem(module.items[0].link, module.items[0].onClick)"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ module.items[0].icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title style="font-size: 16px">{{ module.items[0].title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>

                    </template>
                </v-list>
                <v-list-item>
                    <v-list-item-subtitle>
                        Space Craft Admin
                        <br/>Версия: 2.15.001
                    </v-list-item-subtitle>
                </v-list-item>
            </v-navigation-drawer>
        </v-card>
    </div>

</template>
<script>
    import eventBus from "../utils/eventBus";
    import axios from 'axios';
    import {getURL} from "../settings";
    import loader from "../utils/customizeOptions";

    export default {
        name: "Navigation",
        mounted() {
            eventBus.$on('help-closed', () => {
                this.showHelp = false;
            });
            axios.get(getURL('admin/draft/settings')).then(resp => {
                this.helpInfo = resp.data['Справочник'];
            })
        },
        async beforeMount() {
            await loader().loadOptions();
            let moduleComponents = loader().getOptions(['Настройки админки', 'Набор модулей']);
            console.log(moduleComponents);
            if(!moduleComponents || !moduleComponents.length)
                moduleComponents = [{name: 'Состояние'}, {name: 'Настройки сайта'}];

            moduleComponents.forEach(component => {
                this.moduleNames.push(component.name);
            });
            this.modules = this.modules.filter(module => {
                return this.moduleNames.indexOf(module.name) !== -1;
            })
        },
        data () {
            return {
                moduleNames: [],
                modules: [
                    {
                        name: 'Состояние',
                        items: [
                            { title: 'Панель состояния', icon: 'mdi-speedometer', link: '/' },
                            // { title: 'Аналитика', icon: 'mdi-google-analytics', link: '/analyze' },
                        ]
                    },
                    {
                        name: 'Отель',
                        items: [
                            { title: 'Бронирования', icon: 'mdi-book-account-outline', link: '/orders' },
                            { title: 'Управление', icon: 'mdi-home-city', link: '/business' },
                        ]
                    },
                    {
                      name: 'Услуги',
                      items: [
                          { title: 'Тарифы', icon: 'mdi-cash-multiple', link: '/tariffs' },
                      ]
                    },
                    {
                        name: 'Интернет-магазин',
                        items: [
                            { title: 'Продажи', icon: 'mdi-cash-multiple', link: '/orders' },
                            { title: 'Товары', icon: 'mdi-store', link: '/products'},
                            { title: 'Загрузка товаров', icon: 'mdi-store-plus', link: '/loadmaster'},
                            { title: 'Курсы валют', icon: 'mdi-circle-multiple', link: '/rates' },
                        ]
                    },
                    {
                        name: 'Компания',
                        items: [
                            { title: 'Задачи', icon: 'mdi-briefcase', link: '/tasks'},
                            { title: 'Пользователи', icon: 'mdi-account-group', link: '/employees'},
                            { title: 'Регламенты', icon: 'mdi-state-machine', link: 'regulations'}
                        ]
                    },
                    {
                        name: 'Настройки сайта',
                        items: [
                            { title: 'Основные', icon: 'mdi-cog', link: '/main-settings' },
                            { title: 'Страницы', icon: 'mdi-pan-top-left', link: '/information' },
                            { title: 'Разделы', icon: 'mdi-pan-bottom-left', link: '/category' },
                            { title: 'Блоки', icon: 'mdi-clipboard-list-outline', link: '/blocks' },
                            { title: 'Контент', icon: 'mdi-image', link: '/content' }
                        ]
                    },
                    {
                        name: 'Помощь',
                        items: [
                            { title: 'Справочник', icon: 'mdi-book', onClick: () => {
                                    this.showHelp = true;
                                }}
                        ]
                    }
                ],
                right: null,
                showHelp: false,
                helpInfo: null,
                modulesOpened: ['Состояние', 'Отель', 'Интернет-магазин']
            }
        },
        methods: {
            onClickItem(link, handler) {
                if (handler)
                    handler();
                else
                    this.$router.push(link);
            },
            onModuleClick(moduleName) {
                const moduleSeqId = this.modulesOpened.indexOf(moduleName);
                if(moduleSeqId !== -1)
                    this.modulesOpened.splice(moduleSeqId, 1);
                else
                    this.modulesOpened.push(moduleName);
            }
        }
    }
</script>

<style scoped>
    .main__stack-panel-right {
        position: fixed;
        overflow-y: auto;
        right: 0;
        top: 0;
        z-index: 10;
        width: 40vw;
        min-width: 480px;
    }

    .base__nav-drawer {
        background: rgb(121, 171, 172);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
    }
</style>
